// src/pages/AboutUs.js

import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-gray-100">
      <Header />
      
      <div className="pt-24">
        <section className="container mx-auto px-4 py-16">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-green-400 hover:text-green-300 mb-8 transition-colors duration-200 ease-in-out transform hover:-translate-x-1"
          >
            <FaArrowLeft className="mr-2" /> Back
          </button>
          <div className="text-center mb-10">
            <h2 className="text-5xl font-extrabold text-green-400 mb-4">About Us</h2>
            <p className="text-lg font-light text-gray-300">
              Welcome to Blyve Minecraft Server! We are dedicated to providing an exceptional Minecraft experience for our community. Our team is passionate about creating engaging content, maintaining a stable server, and fostering a friendly environment for players of all skill levels.
            </p>
          </div>
          <div className="flex justify-center mb-8">
            <img
              src="/images/about.jpg"  // Placeholder image path
              alt="About Us"
              className="w-full h-96 object-cover rounded-xl shadow-lg animate-fadeInUp"
            />
          </div>
          <div className="text-lg space-y-8 px-4 md:px-8 lg:px-16 text-center">
            <p className="text-green-400 text-3xl font-semibold mt-12 animate-pulse">
              Our Mission
            </p>
            <p className="text-gray-300">
              Our mission is to build a thriving community where players can explore, create, and collaborate. We strive to offer regular updates, exciting events, and responsive support to ensure every player has a memorable experience on our server.
            </p>
            <p className="text-green-400 text-3xl font-semibold mt-12 animate-pulse">
              Our Team
            </p>
            <p className="text-gray-300">
              The Blyve team is composed of experienced Minecraft enthusiasts who are committed to maintaining high standards of quality and fairness. We believe in transparency, inclusivity, and continuous improvement to serve our community better.
            </p>
          </div>
        </section>
      </div>
      
      <Footer />
    </div>
  );
};

export default AboutUs;
