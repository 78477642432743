// src/pages/InstallMods.js

import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const InstallMods = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-gray-100">
      <Header />
      
      <div className="pt-24">
        <section className="container mx-auto px-4 py-16">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-green-400 hover:text-green-300 mb-8 transition-colors duration-200 ease-in-out transform hover:-translate-x-1"
          >
            <FaArrowLeft className="mr-2" /> Back
          </button>
          <div className="text-center mb-10">
            <h2 className="text-5xl font-extrabold text-green-400 mb-4">Installing Mods</h2>
            <p className="text-lg font-light text-gray-300">
              Enhance your Minecraft experience with mods. Instructions: Coming Soon...
            </p>
          </div>
          <div className="flex justify-center mb-8">
            <img
              src="/images/install.jpg"  // Đường dẫn mới cho hình ảnh
              alt="Installing Mods"
              className="w-full h-96 object-cover rounded-xl shadow-lg"
            />
          </div>
          <div className="text-lg space-y-12 px-4 md:px-8 lg:px-16 text-center">
            <p className="text-gray-200 text-3xl font-semibold mt-12 animate-pulse text-center">
              Coming Soon...
            </p>
            <p className="text-gray-300 mt-6">
              We’re preparing a guide to help you install mods and customize your gameplay.
              Join our Discord server for updates!
            </p>
          </div>
        </section>
      </div>
      
      <Footer />
    </div>
  );
};

export default InstallMods;
