import React from "react";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import Header from "../components/Header"; // Updated path for Header
import Footer from "../components/Footer"; // Updated path for Footer

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 flex flex-col">
      <Header />
      {/* Spacing above */}
      <div className="flex-grow pt-24 flex flex-col items-center justify-center">
        <h1 className="text-6xl font-bold mb-4">404</h1>
        <p className="text-2xl mb-8">Page Not Found</p>
        <button
          onClick={() => navigate("/")}
          className="flex items-center space-x-2 bg-green-600 hover:bg-green-700 transition-colors duration-300 px-4 py-2 rounded-lg"
        >
          <FaHome />
          <span>Go to Home</span>
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
