// src/MusicPlayer.js

import React from "react";
import { MusicProvider } from "./contexts/MusicContext";

const MusicPlayer = ({ children }) => {
  return <MusicProvider>{children}</MusicProvider>;
};

export default MusicPlayer;
