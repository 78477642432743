// src/components/VolumeControl.js

import React, { useContext, useRef, useEffect } from "react";
import { BsMusicNote, BsMusicNoteBeamed } from "react-icons/bs";
import { MusicContext } from "../contexts/MusicContext";

const VolumeControl = () => {
  const {
    isMuted,
    volume,
    changeVolume,
    showVolume,
    toggleVolumeSlider,
    setShowVolume,
  } = useContext(MusicContext);

  const audioRef = useRef(null);
  const volumeControlRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.volume = volume;
      if (!isMuted) {
        audio.play().catch((error) => console.error("Lỗi khi phát nhạc:", error));
      } else {
        audio.pause();
      }
    }
  }, [isMuted, volume]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (volumeControlRef.current && !volumeControlRef.current.contains(event.target)) {
        setShowVolume(false);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [setShowVolume]);

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    changeVolume(newVolume);
  };

  return (
    <div ref={volumeControlRef} className="fixed bottom-4 right-4 flex flex-col items-center space-y-2">
      <audio ref={audioRef} src="/sounds/music.mp3" loop />

      {showVolume && (
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          className="volume-slider"
          aria-label="Adjust volume"
          orient="vertical"
        />
      )}
      <button
        onClick={(e) => {
          e.stopPropagation();
          toggleVolumeSlider();
        }}
        className={`p-2 rounded-full border-2 shadow-lg transition-colors duration-500 ${
          isMuted ? "animate-bounce-muted text-red-500 border-red-500" : "animate-spin-color-sync text-current"
        }`}
        aria-label={showVolume ? "Hide volume slider" : isMuted ? "Show volume slider" : "Show volume slider"}
      >
        <div className="relative">
          {isMuted ? (
            <>
              <BsMusicNote className="text-2xl" />
              <div className="absolute w-8 h-0.5 bg-red-500 transform -rotate-45 top-1/2 -translate-y-1/2" />
            </>
          ) : (
            <BsMusicNoteBeamed className="text-2xl" />
          )}
        </div>
      </button>
    </div>
  );
};

export default VolumeControl;
