// src/pages/ViewMap.js

import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaExpand, FaCompress } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ViewMap = () => {
  const navigate = useNavigate();
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) { // Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
        document.documentElement.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
    }
    // The state will be updated in the fullscreen change event listener
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      const fullscreenElement =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement;
      setIsFullscreen(!!fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
      document.removeEventListener("mozfullscreenchange", handleFullscreenChange);
      document.removeEventListener("MSFullscreenChange", handleFullscreenChange);
    };
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-gray-100 transition-colors duration-500">
      <Header />

      <div className="pt-24 pb-16">
        {/* Back Button */}
        <section className="container mx-auto px-4 py-4">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-green-400 hover:text-green-300 mb-8 transition-colors duration-200 ease-in-out transform hover:-translate-x-1 focus:outline-none"
            aria-label="Go Back"
          >
            <FaArrowLeft className="mr-2" /> Back
          </button>
        </section>

        {/* Map Section */}
        <section
          className={`container mx-auto px-4 transition-all duration-500 ${
            isFullscreen ? "fixed top-0 left-0 w-full h-full z-50 bg-gray-900" : ""
          }`}
        >
          {/* Title, Subtitle, and Fullscreen Button */}
          <div
            className={`flex flex-col items-center mb-4 ${
              isFullscreen ? "px-4 pt-4" : ""
            }`}
          >
            {!isFullscreen && (
              <div className="flex flex-col items-center mb-6 w-full">
                <h2 className="text-5xl font-extrabold text-green-400 mb-4 text-center">
                  Server Map
                </h2>
                <p className="text-lg font-light text-gray-300 text-center">
                  Explore the world of Blyve from your browser!
                </p>
              </div>
            )}

            {/* Fullscreen / Exit Fullscreen Button */}
            <div className="flex items-center space-x-4">
              <button
                onClick={toggleFullscreen}
                className="flex items-center px-4 py-2 bg-gray-800 hover:bg-gray-700 rounded-lg text-green-400 hover:text-green-300 transition-colors duration-300 transform hover:scale-105 focus:outline-none"
                aria-label={isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
              >
                {isFullscreen ? (
                  <>
                    <FaCompress className="mr-2" />
                    Exit Fullscreen
                  </>
                ) : (
                  <>
                    <FaExpand className="mr-2" />
                    Fullscreen
                  </>
                )}
              </button>

              {/* "Blyve" Text when in Fullscreen */}
              {isFullscreen && (
                <span className="text-green-400 font-semibold text-lg">
                  Blyve
                </span>
              )}
            </div>
          </div>

          {/* Map Iframe Container */}
          <div
            className={`flex justify-center ${
              isFullscreen ? "h-full" : "mb-8"
            } transition-all duration-500`}
          >
            <div
              className={`w-full rounded-xl shadow-lg overflow-hidden bg-black ${
                isFullscreen ? "h-full" : "h-[80vh]"
              } transition-all duration-500`}
            >
              <iframe
                src="https://blyve.cloud/dynmap/"
                title="Server Map"
                className="w-full h-full transition-transform duration-500"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </section>
      </div>

      {!isFullscreen && <Footer />}
    </div>
  );
};

export default ViewMap;
