// src/contexts/MusicContext.js

import React, { createContext, useState, useRef, useEffect } from "react";

// Tạo Music Context
export const MusicContext = createContext();

// Tạo Provider Component
export const MusicProvider = ({ children }) => {
  const audioRef = useRef(null);
  const [volume, setVolume] = useState(0); // Bắt đầu với âm lượng 0 để tránh lỗi autoplay
  const [showVolume, setShowVolume] = useState(false); // Ẩn thanh âm lượng mặc định

  // Trạng thái mute dựa trên âm lượng
  const isMuted = volume === 0;

  // Quản lý phát/tạm dừng âm thanh dựa trên âm lượng
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.volume = volume;
      if (volume > 0) {
        audio.play().catch((error) => {
          console.error("Lỗi khi phát nhạc:", error);
        });
      } else {
        audio.pause();
      }
    }
  }, [volume]);

  // Bật/Tắt thanh âm lượng
  const toggleVolumeSlider = () => {
    setShowVolume((prev) => !prev);
    if (!showVolume) {
      setVolume(0.65); // Đặt âm lượng mặc định là 65% khi bật thanh âm lượng
    }
  };

  // Thay đổi âm lượng từ thanh trượt
  const changeVolume = (newVolume) => {
    setVolume(newVolume);
  };

  return (
    <MusicContext.Provider
      value={{
        isMuted,
        volume,
        changeVolume,
        showVolume,
        toggleVolumeSlider,
        setShowVolume,
      }}
    >
      {/* Đường dẫn mới cho âm thanh */}
      <audio ref={audioRef} src="/sounds/music.mp3" loop playsInline />
      {children}
    </MusicContext.Provider>
  );
};
