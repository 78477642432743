// src/components/Header.js

import React, { useState } from "react";
import { FaDiscord, FaBars, FaTimes } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleToggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleCloseMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header className="bg-gray-800 text-gray-200 fixed top-0 left-0 w-full z-50 shadow-md">
      <div className="container mx-auto flex items-center justify-between px-4 py-4">
        {/* Logo và Tiêu đề */}
        <div className="flex items-center space-x-3">
          <Link to="/" onClick={handleCloseMobileMenu}>
            <img
              src="/images/blyve-logo.png" // Đảm bảo đường dẫn logo chính xác
              alt="Blyve Logo"
              className="w-12 h-12 rounded-full object-cover"
            />
          </Link>
          <Link to="/" onClick={handleCloseMobileMenu}>
            <h1 className="text-2xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-blue-500 to-purple-600">
              Blyve
            </h1>
          </Link>
        </div>

        {/* Thanh Điều Hướng (Ẩn trên di động) */}
        <nav className="hidden md:flex space-x-6">
          <NavLink
            exact
            to="/"
            activeClassName="text-green-400"
            className="hover:text-green-400 transition-colors duration-300"
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            activeClassName="text-green-400"
            className="hover:text-green-400 transition-colors duration-300"
          >
            About Us
          </NavLink>
          <NavLink
            to="/services"
            activeClassName="text-green-400"
            className="hover:text-green-400 transition-colors duration-300"
          >
            Services
          </NavLink>
          <NavLink
            to="/contact"
            activeClassName="text-green-400"
            className="hover:text-green-400 transition-colors duration-300"
          >
            Contact
          </NavLink>
          <NavLink
            to="/terms-and-conditions"
            activeClassName="text-green-400"
            className="hover:text-green-400 transition-colors duration-300"
          >
            Terms & Conditions
          </NavLink>
        </nav>

        {/* Nút Discord (Ẩn trên di động) */}
        <div className="hidden md:flex">
          <button
            onClick={() =>
              window.open("https://discord.gg/jUBsjejcpH", "_blank")
            }
            className="flex items-center bg-[#5865F2] hover:bg-[#4752C4] transition-colors duration-300 px-4 py-2 rounded-full"
            aria-label="Join our Discord"
          >
            <FaDiscord className="mr-2 text-xl" />
            <span className="font-medium">Discord</span>
          </button>
        </div>

        {/* Nút Menu di động */}
        <div className="md:hidden flex items-center">
          <button
            onClick={handleToggleMobileMenu}
            className="text-2xl focus:outline-none"
            aria-label="Toggle Mobile Menu"
          >
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>

      {/* Menu di động */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-gray-800 text-gray-200 px-4 pb-4">
          <nav className="flex flex-col space-y-4 mt-2">
            <NavLink
              exact
              to="/"
              activeClassName="text-green-400"
              className="hover:text-green-400 transition-colors duration-300"
              onClick={handleCloseMobileMenu}
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              activeClassName="text-green-400"
              className="hover:text-green-400 transition-colors duration-300"
              onClick={handleCloseMobileMenu}
            >
              About Us
            </NavLink>
            <NavLink
              to="/services"
              activeClassName="text-green-400"
              className="hover:text-green-400 transition-colors duration-300"
              onClick={handleCloseMobileMenu}
            >
              Services
            </NavLink>
            <NavLink
              to="/contact"
              activeClassName="text-green-400"
              className="hover:text-green-400 transition-colors duration-300"
              onClick={handleCloseMobileMenu}
            >
              Contact
            </NavLink>
            <NavLink
              to="/terms-and-conditions"
              activeClassName="text-green-400"
              className="hover:text-green-400 transition-colors duration-300"
              onClick={handleCloseMobileMenu}
            >
              Terms & Conditions
            </NavLink>
            {/* Nút Discord cho di động */}
            <button
              onClick={() =>
                window.open("https://discord.gg/jUBsjejcpH", "_blank")
              }
              className="flex items-center bg-[#5865F2] hover:bg-[#4752C4] transition-colors duration-300 px-4 py-2 rounded-full mt-2"
              aria-label="Join our Discord"
            >
              <FaDiscord className="mr-2 text-xl" />
              <span className="font-medium">Discord</span>
            </button>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
