// src/components/Footer.js

import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaInstagram, FaDiscord } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-gray-200 py-12 mt-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-16">
          
          {/* About Section */}
          <div className="md:w-1/3 text-center">
            <h2 className="text-2xl font-bold mb-4">Blyve Minecraft Server</h2>
            <p className="text-sm">
              Experience the best Minecraft gameplay with our dedicated server. Join our community and embark on exciting adventures!
            </p>
          </div>

          {/* Quick Links Section */}
          <div className="md:w-1/3 text-center">
            <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
            <ul>
              <li className="mb-2">
                <Link to="/" className="hover:text-green-400 transition-colors duration-300">
                  Home
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/about" className="hover:text-green-400 transition-colors duration-300">
                  About Us
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/services" className="hover:text-green-400 transition-colors duration-300">
                  Services
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/contact" className="hover:text-green-400 transition-colors duration-300">
                  Contact
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/terms-and-conditions"
                  className="hover:text-green-400 transition-colors duration-300"
                >
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact & Social Section */}
          <div className="md:w-1/3 text-center">
            <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
            <p className="text-sm mb-4">
              Blyve Studio, Long Tuyen Ward,<br />
              Binh Thuy District, Can Tho City,<br />
              Vietnam.
            </p>
            <p className="text-sm mb-4">Owner: Nguyen Minh Quan</p>
            <div className="flex justify-center space-x-4">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-green-400 transition-colors duration-300"
                aria-label="Facebook"
              >
                <FaFacebookF size={20} />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-green-400 transition-colors duration-300"
                aria-label="Twitter"
              >
                <FaTwitter size={20} />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-green-400 transition-colors duration-300"
                aria-label="Instagram"
              >
                <FaInstagram size={20} />
              </a>
              <a
                href="https://discord.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-green-400 transition-colors duration-300"
                aria-label="Discord"
              >
                <FaDiscord size={20} />
              </a>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="border-t border-gray-700 mt-8 pt-4">
          <p className="text-center text-sm">
            © {new Date().getFullYear()} Blyve Minecraft Server. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
