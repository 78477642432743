import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Header from "../components/Header"; // Updated path for Header
import Footer from "../components/Footer"; // Updated path for Footer

const TermsAndConditions = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <Header />
      {/* Add top padding */}
      <div className="pt-24">
        <section className="container mx-auto px-4 py-16">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-green-400 hover:text-green-300 mb-6"
          >
            <FaArrowLeft className="mr-2" /> Back
          </button>
          <h1 className="text-4xl font-bold mb-8 text-center text-green-400">
            Terms and Conditions
          </h1>
          <div className="text-lg space-y-6">
            <p>
              Welcome to Blyve Minecraft Server. By accessing or using our
              server, you agree to comply with and be bound by the following
              terms and conditions.
            </p>

            <h2 className="text-2xl font-bold text-green-400">
              1. Acceptance of Terms
            </h2>
            <p>
              By accessing the Blyve Minecraft Server, you agree to be bound by
              these terms and conditions. If you do not agree, please do not use
              our services.
            </p>

            <h2 className="text-2xl font-bold text-green-400">2. User Conduct</h2>
            <p>
              Users are expected to behave respectfully towards others. Any form
              of harassment, hate speech, or discrimination is strictly
              prohibited.
            </p>
            <p>
              Do not use any form of cheating, hacking, or third-party software
              to interfere with the game.
            </p>
            <p>
              Do not destroy or cause damage to other players' properties or the
              server's assets.
            </p>

            <h2 className="text-2xl font-bold text-green-400">
              3. User-Generated Content
            </h2>
            <p>
              By creating content on the server, you grant us the right to use,
              copy, modify, and distribute that content for the purpose of
              operating and promoting the server.
            </p>

            <h2 className="text-2xl font-bold text-green-400">
              4. Modifications to Terms
            </h2>
            <p>
              We reserve the right to modify these terms at any time. Changes
              will be posted on this page, and your continued use of the server
              constitutes acceptance of the new terms.
            </p>

            <h2 className="text-2xl font-bold text-green-400">5. Privacy Policy</h2>
            <p>
              We are committed to protecting your privacy. Please review our
              Privacy Policy for more details.
            </p>

            <h2 className="text-2xl font-bold text-green-400">
              6. Limitation of Liability
            </h2>
            <p>
              We are not liable for any losses or damages arising from your use
              of our server.
            </p>

            <h2 className="text-2xl font-bold text-green-400">7. Governing Law</h2>
            <p>
              These terms are governed by the laws of [Your Country]. Any
              disputes will be resolved in the courts of [Your Country].
            </p>

            <h2 className="text-2xl font-bold text-green-400">
              8. Contact Information
            </h2>
            <p>
              If you have any questions about these terms, please contact us at
              [email or contact information].
            </p>

            <h2 className="text-2xl font-bold text-green-400">9. Termination</h2>
            <p>
              We reserve the right to terminate or suspend your access to the
              server at our sole discretion, without prior notice, for conduct
              that we believe violates these terms or is harmful to other users
              or the server itself.
            </p>

            <h2 className="text-2xl font-bold text-green-400">
              10. Donations and Purchases
            </h2>
            <p>
              Any donations or purchases made on the server are final and
              non-refundable. Virtual items have no monetary value and cannot be
              exchanged for real-world currency.
            </p>

            <h2 className="text-2xl font-bold text-green-400">
              11. Intellectual Property Rights
            </h2>
            <p>
              All content and materials on the server, including but not limited
              to text, graphics, logos, and software, are the property of Blyve
              Minecraft Server or its content suppliers and are protected by
              intellectual property laws.
            </p>

            <h2 className="text-2xl font-bold text-green-400">
              12. Links to Other Websites
            </h2>
            <p>
              Our server may contain links to third-party websites or services
              that are not owned or controlled by us. We have no control over
              and assume no responsibility for the content, privacy policies, or
              practices of any third-party websites.
            </p>

            <h2 className="text-2xl font-bold text-green-400">
              13. Disclaimer of Warranties
            </h2>
            <p>
              The server is provided on an "as is" and "as available" basis. We
              make no warranties, expressed or implied, and hereby disclaim all
              other warranties including, without limitation, implied warranties
              or conditions of merchantability or fitness for a particular
              purpose.
            </p>

            <h2 className="text-2xl font-bold text-green-400">14. Severability</h2>
            <p>
              If any provision of these terms is found to be unenforceable or
              invalid, the remaining provisions will remain in effect.
            </p>

            <h2 className="text-2xl font-bold text-green-400">15. Entire Agreement</h2>
            <p>
              These terms constitute the entire agreement between you and Blyve
              Minecraft Server regarding the use of the server and supersede any
              prior agreements.
            </p>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
