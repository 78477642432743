// src/App.js

import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/Home";
import JoinServer from "./pages/JoinServer";
import InstallMods from "./pages/InstallMods";
import ViewMap from "./pages/ViewMap"; // Import ViewMap
import TermsAndConditions from "./pages/TermsAndConditions";
import NotFound from "./pages/NotFound";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/ScrollToTop";
import MusicPlayer from "./MusicPlayer";
import Header from "./components/Header";
import VolumeControl from "./components/VolumeControl";

function App() {
  return (
    <MusicPlayer>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/join-server" element={<JoinServer />} />
        <Route path="/install-mods" element={<InstallMods />} />
        <Route path="/view-map" element={<ViewMap />} /> {/* Added ViewMap route */}
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <VolumeControl />
    </MusicPlayer>
  );
}

export default App;
